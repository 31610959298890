import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';

import classNames from '../../../lib/classnames';
import type { MessageLabelProps } from '../MessageLabel';
import MessageLabel from '../MessageLabel';

export interface ToastNotificationProps
    extends Pick<MessageLabelProps, 'children' | 'className' | 'type'> {
    containerClassName?: string;
    autoDeleteTime?: number;
    setShow: Dispatch<SetStateAction<boolean>>;
    show: boolean;
}

export default function ToastNotification({
    children,
    type,
    containerClassName,
    className,
    show,
    setShow,
    autoDeleteTime = 3000,
}: ToastNotificationProps): JSX.Element {
    useEffect(() => {
        const interval = setInterval(() => {
            setShow(false);
        }, autoDeleteTime);
        return () => {
            clearInterval(interval);
        };
    }, [autoDeleteTime, setShow]);

    return (
        <>
            {show && (
                <div className={classNames('absolute z-50 left-1/2 top-6', containerClassName)}>
                    <MessageLabel type={type} className={className} onClick={() => setShow(false)}>
                        {children}
                    </MessageLabel>
                </div>
            )}
        </>
    );
}
