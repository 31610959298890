import routes from '../../../../config/routes';
import ContainerLarge from '../../../basic/Container/ContainerLarge';
import Image from '../../../basic/Image';
import Heading2 from '../../../elements/Heading/Heading2';
import LinkButton from '../../../elements/LinkButton';
import Paragraph from '../../../elements/Paragraph';

export interface SectionCtaProps {
    MainTitle: string;
    TextDescription: string;
    imageScreen?: string;
}

export default function SectionCta({
    MainTitle,
    TextDescription,
    imageScreen,
}: SectionCtaProps): JSX.Element {
    return (
        <section className="bg-gray-900 dark:bg-gray-200 overflow-hidden px-3">
            <ContainerLarge className="flex flex-col text-center justify-center">
                <div className="lg:w-1/2 mx-auto my-10 lg:my-28">
                    <Heading2 variant="frontend" className="!mt-0 text-white dark:text-gray-900">
                        {MainTitle}
                    </Heading2>
                    <Paragraph variant="frontend" className="text-white dark:text-gray-900">
                        {TextDescription}
                    </Paragraph>
                    <div className="mt-12 mx-auto w-fit">
                        <LinkButton
                            href={routes.adminPublicationCreate.getUrl()}
                            color="alternative">
                            Créer une publication
                        </LinkButton>
                    </div>
                </div>
            </ContainerLarge>
            {imageScreen && (
                <ContainerLarge>
                    <Image
                        src={imageScreen}
                        alt=""
                        className="overflow-visible w-full rounded-t-lg drop-shadow-[40px_0_45px_rgba(229,231,235,0.3)] dark:drop-shadow-[40px_0_45px_rgba(31,41,55,0.3)]"
                    />
                </ContainerLarge>
            )}
        </section>
    );
}
