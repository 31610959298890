import { fill } from '@cloudinary/url-gen/actions/resize';

import routes from '../../../config/routes';
import type { IPublication } from '../../../features/publication';
import type { IUser } from '../../../features/user';
import type IMedia from '../../../shared/interfaces/media.interface';
import type IProviderMetadata from '../../../shared/interfaces/provider-metadata.interface';
import AvatarPartager from '../../basic/AvatarPartager';
import Heading3 from '../../elements/Heading/Heading3';
import Link from '../../elements/Link';
import Paragraph from '../../elements/Paragraph';

export interface TeaserPublicationProps {
    publication: Pick<IPublication, 'slug' | 'title' | 'description'> & {
        logo:
            | (Pick<IMedia, 'url'> & {
                  provider_metadata: Pick<IProviderMetadata, 'public_id'>;
              })
            | null;
    };
    user?: Pick<IUser, 'id' | 'slug' | 'pseudoName'>;
}

export default function TeaserPublication({
    publication,
    user,
}: TeaserPublicationProps): JSX.Element {
    return (
        <article className="flex flex-row p-4 lg:hover:bg-gray-50 lg:dark:hover:bg-gray-700 rounded-2xl">
            <Link href={routes.publicationSlug.getUrl(publication.slug)}>
                <AvatarPartager
                    provider_metadata={publication.logo?.provider_metadata}
                    resizeParameters={fill().width(160).height(160)}
                    name={publication.title}
                    size={20}
                    square
                    className="!w-8 !h-8 md:!w-20 md:!h-20 mr-4 md:mr-8"
                />
            </Link>
            <div className="w-full md:w-[80%]">
                <Link
                    href={routes.publicationSlug.getUrl(publication.slug)}
                    className="no-underline">
                    <Heading3 variant="frontend" level={1} className="!mt-0 !mb-2">
                        {publication.title}
                    </Heading3>
                    <Paragraph className="break-words whitespace-normal" variant="frontend">
                        {publication.description}
                    </Paragraph>
                </Link>
                <div className="flex flex-row text-gray-500 text-sm whitespace-nowrap mt-8">
                    <Link
                        href={routes.getUser.getUrl(user?.slug)}
                        className="flex flex-row space-x-2 mr-2 no-underline">
                        <div className="font-bold text-gray-800 dark:text-white">
                            {user?.pseudoName}
                        </div>
                    </Link>
                </div>
            </div>
        </article>
    );
}
