import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import ContainerLarge from '../components/basic/Container/ContainerLarge';
import ToastNotification from '../components/basic/ToastNotification';
import Heading1 from '../components/elements/Heading/Heading1';
import Heading3 from '../components/elements/Heading/Heading3';
import LinkButton from '../components/elements/LinkButton';
import Paragraph from '../components/elements/Paragraph';
import StandardWebsiteLayout from '../components/modules/Layout/StandardLayout/StandardWebsiteLayout';
import Seo from '../components/modules/Seo';
import TeaserPublication from '../components/modules/TeaserPublication';
import SectionCta from '../components/modules/Website/SectionCta';
import PublicationSlug from '../components/PublicationSlug';
import { getFullUrl } from '../components/SchemaOrg/SchemaOrg.service';
import IllustrationPartager from '../components/svgs/illustrations/partager-home.svg';
import routes from '../config/routes';
import type { IOfferType } from '../features/offerType';
import type { IPost } from '../features/post';
import type { IPublication } from '../features/publication';
import {
    getPublications,
    getPublicationWithSubscribersCountPaginatedAndSorted,
} from '../features/publication';
import type { SchemaOrgBlogDefinition } from '../features/publication/schema';
import { getBlogSchema } from '../features/publication/schema';
import type { ISubscriberList } from '../features/subscriberList';
import type { IUser } from '../features/user';
import { isCloudfrontHealthCheck, isMainDomain } from '../lib/helper/helper';
import useInfiniteScroll from '../lib/hooks/useInfiniteScroll';
import type { PageContext, PageRedirect } from '../lib/interfaces/page';
import { goTo } from '../lib/page-helper';
import type IMedia from '../shared/interfaces/media.interface';
import type IProviderMetadata from '../shared/interfaces/provider-metadata.interface';

interface PickedSubscriberList
    extends Pick<
        ISubscriberList,
        'id' | 'enable' | 'sourceCode' | 'polarisId' | 'externalId' | 'price'
    > {
    offerType: Pick<IOfferType, 'name'>;
}

interface PickedPost
    extends Pick<
        IPost,
        'id' | 'title' | 'subtitle' | 'createdAt' | 'seoTitle' | 'seoSubtitle' | 'text'
    > {
    cover:
        | (Pick<IMedia, 'url'> & {
              provider_metadata: Pick<IProviderMetadata, 'public_id'>;
          })
        | null;
    seoCover: Pick<IMedia, 'url'> | null;
}

interface PickedPublication
    extends Pick<
        IPublication,
        'id' | 'title' | 'slug' | 'description' | 'createdAt' | 'about' | 'thematic' | 'isPaid'
    > {
    posts: PickedPost[];
    subscriberLists: PickedSubscriberList[];
    user: Pick<IUser, 'id' | 'pseudoName' | 'slug'>;
    logo: Pick<IMedia, 'url' | 'provider_metadata'> | null;
}

const START = 0;
const LIMIT = 10;

interface IndexProps {
    publicationsProp?: PickedPublication[];
    publicationsTotal?: number;
    publication?: PickedPublication;
    schemaOrgDefinition?: SchemaOrgBlogDefinition;
    canonicalUrl?: string;
    domain?: string;
}

export default function Index({
    publicationsProp = [],
    publicationsTotal = 0,
    publication,
    schemaOrgDefinition,
    canonicalUrl,
    domain,
}: IndexProps): JSX.Element {
    const [publications, setPublications] = useState<PickedPublication[]>(publicationsProp);
    const [start, setStart] = useState<number>(START);
    const { data: session } = useSession();

    const [isShowError, setIsShowError] = useState<boolean>(false);
    const router = useRouter();
    const { errorMessage } = router.query;

    async function handleMorePagination(): Promise<void> {
        const nextStart = start + LIMIT;
        try {
            const { data: fetchedPublications } =
                await getPublicationWithSubscribersCountPaginatedAndSorted<PickedPublication>(
                    nextStart,
                    LIMIT,
                );
            setStart(nextStart);
            setPublications((currentPublications) => [
                ...currentPublications,
                ...fetchedPublications,
            ]);
        } catch (error) {
            console.error(error);
        }
    }

    const loader = useInfiniteScroll({
        isActive: publications.length < publicationsTotal,
        fetchMore: handleMorePagination,
    });

    useEffect(() => {
        if (typeof errorMessage !== 'string') {
            return;
        }
        setIsShowError(true);
    }, [errorMessage]);

    if (publication && domain && canonicalUrl && schemaOrgDefinition) {
        return (
            <PublicationSlug
                publication={publication}
                posts={publication.posts || []}
                totalPosts={publication.posts?.length || 0}
                canonicalUrl={canonicalUrl}
                schemaOrgDefinition={schemaOrgDefinition}
                domain={domain}
            />
        );
    }

    return (
        <StandardWebsiteLayout>
            <Seo
                title="Partager - Écrire et partager avec votre communauté"
                description={`Partager vous permet d'atteindre vos objectifs d'indépendance en écrivant votre newsletter.`}
                url={routes.home.getUrl()}
                isCanonical
            />
            <ToastNotification setShow={setIsShowError} show={isShowError} type="error">
                {errorMessage}
            </ToastNotification>
            <div className="h-full dark:bg-gray-900 flex flex-col">
                <section className="bg-gray-50 dark:bg-gray-800 px-3 pt-24 pb-10 md:py-24 lg:py-48">
                    <ContainerLarge className="container mx-auto grow grid lg:grid-cols-2 lg:gap-28 place-items-center">
                        <div>
                            <IllustrationPartager
                                viewBox="0 0 571 457"
                                className="max-w-full md:max-w-sm lg:max-w-none h-auto"
                            />
                        </div>
                        <div>
                            <Heading1>Écrire et partager avec votre communauté</Heading1>
                            <Paragraph variant="frontend">
                                Il est facile et gratuit de publier vos réflexions sur
                                n&apos;importe quel sujet et de partager avec des millions de
                                lecteurs.
                            </Paragraph>
                            {!session && (
                                <div className="mt-12">
                                    <LinkButton href={routes.register.getUrl()}>
                                        Commencer à écrire
                                    </LinkButton>
                                </div>
                            )}
                        </div>
                    </ContainerLarge>
                </section>
                <section className="py-20">
                    <ContainerLarge className="lg:grid lg:grid-cols-12 lg:gap-x-12">
                        <div className="col-span-11 flex flex-col space-y-4">
                            <Heading3 id="publications" variant="frontend" level={2} className="!mt-0 !md:mb-16">
                                Publications en tendances
                            </Heading3>
                            {publications.map((currentPublication, index) => {
                                if (publications?.length === index + 1) {
                                    return (
                                        <div ref={loader} key={currentPublication.id}>
                                            <TeaserPublication
                                                publication={currentPublication}
                                                user={currentPublication.user}
                                            />
                                        </div>
                                    );
                                }

                                return (
                                    <TeaserPublication
                                        key={currentPublication.id}
                                        publication={currentPublication}
                                        user={currentPublication.user}
                                    />
                                );
                            })}
                        </div>
                    </ContainerLarge>
                </section>
                <SectionCta
                    MainTitle="Commencez à écrire"
                    TextDescription="Partager est l'unique plateforme ultra simple dont vous aurez besoin pour communiquer vos idées grâce à votre newsletter."
                />
            </div>
        </StandardWebsiteLayout>
    );
}

export async function getServerSideProps({
    req,
}: PageContext): Promise<{ props: IndexProps } | PageRedirect> {
    const hostName = req.headers.host;
    if (hostName && !isCloudfrontHealthCheck(hostName)) {
        try {
            if (isMainDomain(hostName)) {
                const { data: publications, meta } =
                    await getPublicationWithSubscribersCountPaginatedAndSorted<PickedPublication>(
                        START,
                        LIMIT,
                    );
                return {
                    props: {
                        publicationsProp: publications,
                        publicationsTotal: meta.pagination.total,
                        canonicalUrl: getFullUrl(req, routes.home.getUrl()),
                        domain: hostName,
                    },
                };
            }

            const queryObj = {
                filters: { domain: hostName },
                fields: ['id', 'title', 'slug', 'description', 'createdAt', 'about', 'thematic'],
                populate: {
                    logo: {
                        fields: ['url'],
                    },
                    user: {
                        fields: ['pseudoName', 'slug'],
                    },
                    posts: {
                        fields: ['id', 'title', 'subtitle', 'createdAt'],
                        populate: {
                            cover: {
                                fields: ['url'],
                            },
                        },
                    },
                },
            };

            const { data } = await getPublications<PickedPublication>(queryObj);

            if (!data[0]) {
                // if no publication exists with this custom domain
                // redirect user to https://app.partager.io/
                return goTo(process.env.NEXT_PUBLIC_APP_URL);
            }

            const schemaOrgDefinition = getBlogSchema(data[0]);

            return {
                props: {
                    publication: data[0],
                    domain: hostName,
                    schemaOrgDefinition,
                    canonicalUrl: getFullUrl(req, routes.home.getUrl()),
                },
            };
        } catch (error) {
            console.error(error);
        }
    }
    return {
        props: {},
    };
}
