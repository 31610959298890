import type { MouseEventHandler, ReactNode } from 'react';

import classNames from '../../../lib/classnames';
import IconX from '../../svgs/illustrations/prettier-x.svg';

export interface MessageLabelProps {
    children: ReactNode;
    type?: 'success' | 'error' | 'info' | 'warning';
    onClick: MouseEventHandler;
    className?: string;
}

export default function MessageLabel({
    children,
    onClick,
    type = 'info',
    className,
}: MessageLabelProps): JSX.Element {
    return (
        <div
            className={classNames(
                'flex gap-3 p-4 mb-4 rounded-lg',
                {
                    'bg-blue-100 dark:bg-blue-200': type === 'info',
                    'bg-red-100 dark:bg-red-200': type === 'error',
                    'bg-green-100 dark:bg-green-200': type === 'success',
                    'bg-orange-100 dark:bg-orange-200': type === 'warning',
                },
                className,
            )}>
            <div
                className={classNames('text-sm font-medium', {
                    'text-blue-700 dark:text-blue-800': type === 'info',
                    'text-red-700 dark:text-red-800': type === 'error',
                    'text-green-700 dark:text-green-800': type === 'success',
                    'text-orange-700 dark:text-orange-800': type === 'warning',
                })}>
                {children}
            </div>
            <button
                type="button"
                onClick={onClick}
                className={classNames(
                    '-mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8',
                    {
                        'bg-blue-100 text-blue-500 focus:ring-blue-400 hover:bg-blue-200 dark:bg-blue-200 dark:text-blue-600 dark:hover:bg-blue-300':
                            type === 'info',
                        'bg-red-100 text-red-500 focus:ring-red-400 hover:bg-red-200 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300':
                            type === 'error',
                        'bg-green-100 text-green-500 focus:ring-green-400 hover:bg-green-200 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300':
                            type === 'success',
                        'bg-orange-100 text-orange-500 focus:ring-orange-400 hover:bg-orange-200 dark:bg-orange-200 dark:text-orange-600 dark:hover:bg-orange-300':
                            type === 'warning',
                    },
                )}
                aria-label="Close">
                <span className="sr-only">Close</span>
                <IconX />
            </button>
        </div>
    );
}
